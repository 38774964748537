<template>
  <div>
    <el-dialog
      :title="title + '子菜单'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item
                label="模块编号"
                prop="moduleNo"
                style="width: 100%"
              >
                <el-input
                  ref="moduleNo"
                  v-model="formInline.moduleNo"
                  placeholder="请输入模块编码"
                  maxlength="20"
                  :disabled="add"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item
                label="模块名称"
                prop="moduleName"
                style="width: 100%"
              >
                <el-input
                  ref="moduleName"
                  maxlength="20"
                  v-model="formInline.moduleName"
                  placeholder="请输入模块名称"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item
                label="模块参数"
                prop="moduleParam"
                style="width: 100%"
              >
                <el-input
                  ref="moduleParam"
                  maxlength="20"
                  v-model="formInline.moduleParam"
                  placeholder="请输入模块参数"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item label="排序" prop="dispOrder" style="width: 100%">
                <el-input
                  ref="dispOrder"
                  maxlength="20"
                  v-model="formInline.dispOrder"
                  placeholder="请输入排序"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="determine('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      add: false,
      //表单
      formInline: {},
      //表单验证
      rules: {
        moduleNo: [
          {
            required: true,
            message: "请输入模块编号，不能为空",
            trigger: "blur",
          },
        ],
        moduleName: [
          {
            required: true,
            message: "请输入模块名称，不能为空",
            trigger: "blur",
          },
        ],
        moduleParam: [
          {
            required: true,
            message: "请输入模块参数，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
    },
    show(item, val, index) {
      this.formInline = {};
      this.title = item;
      this.showing = true;
      this.add = false;
      this.formInline.parentModule = this.$route.query.moduleNo;
      if (item == "编辑") {
        this.add = true;
        this.formInline = JSON.parse(JSON.stringify(val));
      }
    },
    // 编辑
    determine(formData) {
      // 表单验证-----------
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.formInline.dispOrder = Number(this.formInline.dispOrder);
          if (this.title == "创建") {
            let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
            postRequest("insertSysModule", this.formInline).then((res) => {
              this.$nextTick(() => {
                loadingInstance.close();
              }); //不回调
              if (res.status == 200) {
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "error",
                  hasClose: true,
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    updateStation() {
      postRequest("/updateSysModule", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
  height: 90px;
}
.banbentwoItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.el-radio__inner {
  height: 24px !important;
  width: 24px !important;
}
.el-radio__inner::after {
  height: 10px;
  width: 10px;
}
input {
  opacity: 0.65;
}
</style>